import React from "react"
// import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../layouts/index"
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share'
import { FacebookIcon,TwitterIcon,LinkedinIcon } from 'react-share';
import '../components/blog/blog.scss'
import { Link } from "@reach/router";
import { IoIosArrowRoundBack } from "react-icons/io"; 
import Seo from "../components/seo/seo"
import { Helmet } from "react-helmet"
import  hljs from "highlightjs"
import 'highlightjs/styles/foundation.css'
import ReactHtmlParser from 'react-html-parser';
import TalkyardCommentsIframe from '@debiki/gatsby-plugin-talkyard'

class BlogPostTemplate extends React.Component {
  
  componentDidMount() {
    document.querySelectorAll("pre code").forEach(block => {
      hljs.highlightBlock(block);
    });  
  }

  render() {
    const node = this.props.pageContext.node;
    const allFiles = this.props.pageContext.allFiles;
    const url = this.props.location.href;
    const hostName = 'https://www.webodrop.com';

    let article_body_elements = new ReactHtmlParser(node.body.processed, {
      transform: function transform(imgNode) {
        if (imgNode.type === 'tag' && imgNode.name === 'img') {
          let uuid = imgNode.attribs["data-entity-uuid"];
          let i = 0;
          for (i = 0; i < allFiles.length; i++) {
            if (allFiles[i].node.uuid === uuid &&
              allFiles[i].node.localFile) {
              return <Img fluid={allFiles[i].node.localFile.childImageSharp.fluid}/>
            }
          }
        }
  
        return undefined;
      }
    });

    return (
      <Layout>
        <Helmet>
          <meta property="og:url" content={url} />
          <meta property="og:title" content={node.title} />
          <meta property="og:image" content={`${hostName}${node.relationships.field_img.relationships.field_media_image.localFile.childImageSharp.fixed.src}`} />
        </Helmet>
        <Seo title={`Blog | ${node.title}`} url={url}/>
        <div className="wd-blog-detail">
          <div className="wd-blog-detail__img-title">
            {node.relationships.field_img.relationships.field_media_image != null &&
              <Img className="wd-blog-detail__img" fixed={node.relationships.field_img.relationships.field_media_image.localFile.childImageSharp.fixed} />
            }
            <h2 className="wd-blog-detail__title">{node.title}</h2>
          </div>
          <div className="wd-blog-detail__content">
            <div className="wd-blog-detail__container">
              <div className="wd-blog-detail__body wysiwyg_container" >
                { article_body_elements }
              </div>
              
              <div className="wd-blog-detail__share-post">
                <FacebookShareButton className="wd-blog-detail__share-btn" url={url}>
                  <FacebookIcon size={32} round={true} className="wd-blog-detail__share-icon" />
                </FacebookShareButton>

                <LinkedinShareButton className="wd-blog-detail__share-btn" url={url}>
                  <LinkedinIcon size={32} round={true} className="wd-blog-detail__share-icon" />
                </LinkedinShareButton>

                <TwitterShareButton className="wd-blog-detail__share-btn" url={url}>
                  <TwitterIcon size={32} round={true} className="wd-blog-detail__share-icon" />
                </TwitterShareButton>
              </div>
            </div>
            <div className="wd-blog-detail__link">
              <Link to="/blog" className="wd-button wd-button--1"> 
                <IoIosArrowRoundBack className="wd-button__icon wd-button__icon--3"/>Revenir à la liste des articles
              </Link>
            </div> 
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

// export const pageQuery = graphql`
//   query BlogPostBySlug($slug: String!) {
//     site {
//       siteMetadata {
//         title
//         author
//       }
//     }
//     markdownRemark(fields: { slug: { eq: $slug } }) {
//       id
//       excerpt(pruneLength: 160)
//       html
//       frontmatter {
//         title
//         date(formatString: "MMMM DD, YYYY")
//         description
//       }
//     }
//   }
// `